export function formatToDateOnly(date: Date) {
  return date.toISOString().split('T')[0];
}

export function ingredientWithUnitValue(ingredientName: string | undefined, unitValue: number | string, unit: string = 'g') {
  if(!ingredientName) {
    return '??';
  }

  return ingredientName + ' ' + unitValue + unit;
}

export function randomFromInterval(min: number, max: number) { 
  return Math.floor(Math.random() * (max - min + 1) + min);
}